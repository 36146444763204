import { Suspense, StrictMode, useState } from "react";
import "./scss/core.scss";
import "./scss/rtl-support.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./i18n/i18n";
import { useTranslation } from "react-i18next";
import LoadingPage from "./components/Loading";
import Navbar from "./components/Navbar";
import NavbarDots from "./components/NavbarDots";
import Home from "./pages/Home";
import Skills from "./pages/Skills";
import Portfolio from "./pages/Portfolio";
import Services from "./pages/Services";
import Reviews from "./pages/Reviews";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
import Sidebar from "./components/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";
import WhatsappICON from "./components/WhatsappICON";
import Pricing from "./pages/Pricing";
import Blog from "./pages/Blog";

const App = () => {
  const { i18n } = useTranslation();

  document.dir = i18n.dir();

  // SOCIAL MEDIA LINKS
  const media = [
    {
      icon: <FontAwesomeIcon icon={FaInstagram} />,
      link: "#",
    },
    {
      icon: <FontAwesomeIcon icon={FaWhatsapp} />,
      link: "https://api.whatsapp.com/send?phone=212701820367&text=Hello%20Achraf",
    },
    {
      icon: <FontAwesomeIcon icon={FaFacebook} />,
      link: "#",
    },
  ];

  const [activePage, setActivePage] = useState("home");

  const emptyLinks = document.querySelectorAll('a[href="#"]');

  emptyLinks.forEach((a) => {
    a.addEventListener("click", function (e) {
      e.preventDefault();
    });
  });

  return (
    <Suspense>
      <BrowserRouter>
        <LoadingPage />

        <Navbar />

        <main>
          <div className="columns is-gapless">
            <div className="column is-8">
              <NavbarDots activePage={activePage} />

              <div className="sections-container">
                <Routes>
                  <Route
                    path="/"
                    element={<Home setActivePage={setActivePage} />}
                  />
                  <Route
                    path="/skills"
                    element={<Skills setActivePage={setActivePage} />}
                  />
                  <Route
                    path="/portfolio"
                    element={<Portfolio setActivePage={setActivePage} />}
                  />
                  <Route
                    path="/services"
                    element={<Services setActivePage={setActivePage} />}
                  />
                  <Route
                    path="/pricing"
                    element={<Pricing setActivePage={setActivePage} />}
                  />
                  <Route
                    path="/reviews"
                    element={<Reviews setActivePage={setActivePage} />}
                  />
                  <Route
                    path="/contact"
                    element={<Contact setActivePage={setActivePage} />}
                  />
                  <Route
                    path="/blog"
                    element={<Blog setActivePage={setActivePage} />}
                  />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </div>
            </div>

            <div className="column is-4">
              <Sidebar />
            </div>
          </div>
        </main>

        <WhatsappICON media={media} />
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
