import { useTranslation } from "react-i18next";
import { FaEnvelope } from "react-icons/fa";

import { useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserDoctor } from "@fortawesome/free-solid-svg-icons";
import { ReactTyped } from "react-typed";

// HOME PAGE COMPONENT
const Home = ({ setActivePage }) => {
  const { t } = useTranslation();

  useEffect(() => {
    setActivePage("home");
  }, []);

  return (
    <section id="home">
      <div className="section-container">
        <div className="home-content">

          <h1>
            {t("home.heading")} {" "}
            <ReactTyped
              strings={[t("home.headingTypes.ui"), t("home.headingTypes.app"), t("home.headingTypes.site")]}
              typeSpeed={120}
              backSpeed={40}
              attr=""
              loop
            >
            </ReactTyped>
          </h1>


          <h3>{t("home.subtitle")}</h3>

          <p>{t("home.short-descrip")}</p>

          <div className="buttons">
            <a
              href="#"
              className="button is-rounded pph-btn"
              target="_blank"
            >
              <span className="icon">
                <FontAwesomeIcon icon={faUserDoctor} />
              </span>

              <span>{t("home.hire-btn")}</span>
            </a>

            <Link className="button is-text contact-btn" to="contact">
              <span className="icon">
                <FaEnvelope />
              </span>

              <span className="is-uppercase">{t("home.contact-btn")}</span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
